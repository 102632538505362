import { http } from "../http-common";


const liveLessonGraph = (data: any) => {
    return http.get<any>(`/live-lessons/status-count${data}`);
};

const stats = () => {
    return http.get<any>(`/dashboard/all-stats`);
};

const courseSales = () => {
    return http.get<any>(`/dashboard/course-sales`);
};

const lastPurchasedCourse = () => {
    return http.get<any>(`/dashboard/last-purchased-course`);
};

const teacherStats = (limit?: number) => {
    const queryParams = limit && limit > 0 ? `?limit=${limit}` : '';
    return http.get<any>(`/dashboard/teacher-stats${queryParams}`);
};


const DashboardService = {
    liveLessonGraph,
    stats,
    courseSales,
    lastPurchasedCourse,
    teacherStats
};

export default DashboardService;