import React, { useState } from 'react';
import { Button, Col, Drawer, Form, Input, Row, Switch, Upload, UploadFile, UploadProps } from 'antd';
import ExplanationService from '../../../services/ExplanationService';
import { openNotification } from '../../../utils';
import { UploadOutlined } from '@ant-design/icons';
import ReactQuill from 'react-quill';

const { Item } = Form;

interface ExplanationCreateProps {
  getData: any;
  subjectId: number;
}

const ExplanationCreate: React.FC<ExplanationCreateProps> = ({ subjectId, getData }) => {
  const [form] = Form.useForm();
  const [isFile, setIsFile] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const create = (values: any) => {
    setLoading(true);
    const postObj = {
      title: values.name,
      description: isFile ? "" : values.description,
      subjectId: subjectId,
      type: isFile ? "FILE" : "TEXT",
    };

    ExplanationService.create(postObj, fileList[0])
      .then(() => {
        getData();
        openNotification('success', 'Başarılı', 'Konu Anlatımı Başarıyla Oluşturuldu.');
        setShowDrawer(false);
        setFileList([]);
        setPreviewUrl(null);
        form.resetFields();
      })
      .catch((e: Error) => {
        openNotification('error', 'Başarısız', 'Konu Anlatımı oluşturulamadı.');
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const props: UploadProps = {
    onRemove: () => {
      setFileList([]);
      setPreviewUrl(null);
    },
    beforeUpload: (file) => {
      if (fileList.length >= 1) {
        openNotification('warning', 'Uyarı', 'Sadece bir adet dosya yükleyebilirsiniz');
        return Upload.LIST_IGNORE;
      }
      setFileList([file]);
      setPreviewUrl(URL.createObjectURL(file));
      return false;
    },
    fileList,
    defaultFileList: fileList,
    accept: '.pdf',
    multiple: false,
  };

  return (
    <>
      <Button
        className='btn btn-hover-dark bg-saha-purple font-color-white fw-700 btn-text'
        onClick={() => setShowDrawer(true)}
      >
        Konu Anlatımı Ekle
      </Button>
      <Drawer
        title="Yeni Konu Anlatımı Oluştur"
        extra={
          <Switch checked={isFile} onChange={() => setIsFile(!isFile)} className='w-full' />
        }
        placement="right"
        onClose={() => setShowDrawer(false)}
        open={showDrawer}
      >
        <Row justify={'center'}>
          <Col xl={24} xs={24}>
            <Form
              form={form}
              onFinish={create}
              layout="vertical"
              initialValues={{ description: '', file: undefined }}
            >
              <Item
                name="name"
                label="Başlık"
                rules={[
                  {
                    required: true,
                    message: 'Konu Anlatımı adı zorunlu',
                  },
                ]}
              >
                <Input />
              </Item>
              {!isFile ? (
                <Item
                  name="description"
                  label="İçerik"
                  rules={[
                    {
                      required: true,
                      message: 'Açıklama alanı zorunludur',
                    },
                  ]}
                >
                  <ReactQuill className='mb-24' theme="snow" />
                </Item>
              ) : (
                <Item
                  name="file"
                  label="İçerik"
                  rules={[
                    {
                      required: fileList.length === 0,
                      message: 'Dosya yüklenmesi zorunludur',
                    },
                  ]}
                >
                  <Upload {...props}>
                    <Button icon={<UploadOutlined />}>Yükle</Button>
                  </Upload>
                  {previewUrl && (
                    <object
                      data={previewUrl}
                      type="application/pdf"
                      width="100%"
                      height="500px"
                      style={{ marginTop: '16px' }}
                    >
                      <p>
                        PDF önizlemeniz görüntülenemiyor.{' '}
                        <a href={previewUrl}>PDF'yi indir</a>
                      </p>
                    </object>
                  )}
                </Item>
              )}
              <Item>
                <Row justify={'end'}>
                  <Button
                    className='btn btn-hover-dark bg-saha-purple font-color-white fw-700 btn-text'
                    htmlType="submit"
                    loading={loading}
                  >
                    Kaydet
                  </Button>
                </Row>
              </Item>
            </Form>
          </Col>
        </Row>
      </Drawer>
    </>
  );
};

export default ExplanationCreate;
