import React, { useEffect, useState } from 'react';
import { Card, Table } from 'antd';
import DashboardService from '../../services/DashboardService';
import { formatCurrency } from '../../utils';

interface TeacherStatsProps {
    limit?: number; // Optional limit parameter
}

const TeacherStatsTable: React.FC<TeacherStatsProps> = ({ limit = 0 }) => {
    const [tableData, setTableData] = useState<any[]>([]);

    const fetchTeacherStats = async () => {
        try {
            const response = await DashboardService.teacherStats(limit); // Call teacherStats with limit
            const data = response.data;

            const formattedData = data.map((item: any, index: number) => ({
                key: index + 1,
                teacherName: item.teacherName,
                teacherEmail: item.teacherEmail,
                studentCount: item.studentCount,
                totalPackages: item.totalPackages,
                totalRevenue: `${item.totalRevenue}`,
                totalCommission: `${item.totalCommission}`,
            }));

            setTableData(formattedData);
        } catch (error) {
            console.error('Öğretmen istatistikleri alınırken hata oluştu:', error);
        }
    };

    useEffect(() => {
        fetchTeacherStats();
    }, [limit]);

    const columns = [
        {
            title: 'Öğretmen',
            dataIndex: 'teacherName',
            key: 'teacherName',
            width: 300,
            render: (text: string, record: any) => <span><b>{text}</b> <br />{record.teacherEmail}</span>,
        },
        {
            title: 'Öğrenci Sayısı',
            dataIndex: 'studentCount',
            key: 'studentCount',
            render: (text: number) => <span>{text}</span>,
        },
        {
            title: 'Toplam Paket Sayısı',
            dataIndex: 'totalPackages',
            key: 'totalPackages',
            render: (text: number) => <span>{text}</span>,
        },
        {
            title: 'Toplam Ciro',
            dataIndex: 'totalRevenue',
            key: 'totalRevenue',
            render: (text: any, record: any) => (
                <span style={{ color: '#4caf50', fontWeight: 'bold' }}>
                    {formatCurrency(record.totalRevenue)}
                </span>
            ),
        },
        {
            title: 'Öğretmen Komisyonu',
            dataIndex: 'totalCommission',
            key: 'totalCommission',
            render: (text: any, record: any) => (
                <span style={{ color: '#ff9800', fontWeight: 'bold' }}>
                    {formatCurrency(record.totalCommission)}
                </span>
            ),
        },
    ];

    return (
        <Card style={{ width: '100%' }} className="border-radius-md mt-5">
            <h3 className="fs-20 fw-600 mb-16">Öğretmen İstatistikleri</h3>
            <Table
                columns={columns}
                dataSource={tableData}
                pagination={false}
                scroll={{ x: 'max-content' }}
                bordered
            />
        </Card>
    );
};

export default TeacherStatsTable;