import React, { useEffect, useState } from 'react';
import { Card, Table } from 'antd';
import DashboardService from '../../services/DashboardService';
import dayjs from 'dayjs';
import { formatCurrency } from '../../utils';

const LastPurchasedCoursesTable = () => {
    const [tableData, setTableData] = useState<any[]>([]);

    const fetchLastPurchasedCourses = async () => {
        try {
            const response = await DashboardService.lastPurchasedCourse();
            const data = response.data;

            const formattedData = data.map((item: any, index: number) => ({
                key: index + 1,
                courseName: item.courseName,
                studentName: item.studentName,
                purchaseDate: dayjs(item.purchaseDate).format('DD MMMM YYYY'),
                price: `${item.price}`,
            }));

            setTableData(formattedData);
        } catch (error) {
            console.error('Son satın alınan kurslar alınırken hata oluştu:', error);
        }
    };

    useEffect(() => {
        fetchLastPurchasedCourses();
    }, []);

    const columns = [
        {
            title: 'Kurs Adı',
            dataIndex: 'courseName',
            key: 'courseName',
            width: 300,
            render: (text: string) => <b>{text}</b>,
        },
        {
            title: 'Öğrenci Adı',
            dataIndex: 'studentName',
            key: 'studentName',
        },
        {
            title: 'Satın Alma Tarihi',
            dataIndex: 'purchaseDate',
            key: 'purchaseDate',
        },
        {
            title: 'Fiyat',
            dataIndex: 'price',
            key: 'price',
            render: (text: string) => (
                <span style={{ color: '#ff4d4f', fontWeight: 'bold' }}>{formatCurrency(text)}</span>
            ),
        },
    ];

    return (
        <Card style={{ width: '100%' }} className='border-radius-md mt-5'>
            <h3 className="fs-20 fw-600 mb-16">Son Satın Alınan Kurslar</h3>
            <Table
                columns={columns}
                dataSource={tableData}
                pagination={false}
                scroll={{ x: 'max-content' }}
                bordered
            />
        </Card>
    );
};

export default LastPurchasedCoursesTable;