import { Card, Col, Row } from 'antd'
import { ReactComponent as PackagesIcon } from '../../assets/Dashboard/total-packages.svg'
import { PieChartIcon } from 'hugeicons-react';

const TotalPackages = (data: any) => {
    return (
        <Card className='border-radius-md'>
            <Row gutter={24} align={'middle'}>
                <Col >
                    <div className='d-flex p-16 border-radius-lg' style={{ background: '#663690' }}>
                        <PieChartIcon color='white' />
                    </div>
                </Col>
                <Col span={18}>
                    <p className='m-0 font-color-gray fs-14 fw-500'>Toplam Paket</p>
                    <h3 className='m-0 font-color-dark-blue fs-24 fw-700'>{data?.data?.totalPackages ?? '0'}</h3>
                </Col>
            </Row>
        </Card>
    )
}

export default TotalPackages