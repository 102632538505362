import { Card, Col, Row } from 'antd'
import { ReactComponent as StudentIcon } from '../../assets/Dashboard/total-students-icon.svg'
import { Mortarboard01Icon } from 'hugeicons-react';

const TotalStudent = (data: any) => {
  return (
    <Card className='border-radius-md'>
      <Row gutter={24} align={'middle'}>
        <Col>
          <div className='d-flex p-16 bg-cloud border-radius-lg' style={{ background: '#663690' }}>
            <Mortarboard01Icon color='white' />
          </div>
        </Col>
        <Col span={18}>
          <p className='m-0 font-color-gray fs-14 fw-500'>Toplam Öğrenci</p>
          <h3 className='m-0 font-color-dark-blue fs-24 fw-700'>{data?.data?.total ?? '0'}</h3>
        </Col>
      </Row>
    </Card>
  )
}

export default TotalStudent