import { Card, Col, Row } from 'antd';
import ReactApexChart from 'react-apexcharts';
import DashboardService from '../../services/DashboardService';
import { useEffect, useState } from 'react';
import DateRange from '../Common/DateRange';
import dayjs from 'dayjs';

const LiveLessonGraph = () => {
  const [series, setSeries] = useState<any>([]);
  const [count, setCount] = useState<any>();
  const [dateRange, setDateRange] = useState<any>([]);

  const [options, setOptions] = useState<any>({
    chart: {
      height: 350,
      type: 'line',
      toolbar: {
        show: false, // Hide the toolbar
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 5, // Makes the top corners of the bars rounded
        columnWidth: '50%', // Defines the width of the columns
      },
    },
    stroke: {
      width: [4, 4, 4], // Same width for all series
      curve: 'smooth', // Smooth curves
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: [1],
    },
    xaxis: {
      type: 'category',
      labels: {
        formatter: function (val: string) {
          return dayjs(val).format('DD/MM/YYYY');
        },
      },
    },
    yaxis: [],
    legend: {
      horizontalAlign: 'left',
    },
    colors: ['#FED449', '#F47D38', '#6324F5'],
  });

  const getData = (dateRange: any) => {
    let startDate = dayjs(dateRange[0]).format('YYYY-MM-DDT00:00:00');
    let endDate = dayjs(dateRange[1]).format('YYYY-MM-DDT23:59:59');
    DashboardService.liveLessonGraph(`?startDate=${startDate}&endDate=${endDate}`)
      .then((response: any) => {
        const data = response.data;
        let totalCount = 0;
        Object.values(data).forEach((dayData: any) => {
          totalCount += dayData.approved + dayData.created + dayData.completed;
        });
        setCount(totalCount);
        updateSeries(data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (dateRange.length === 2) {
      getData(dateRange);
    }
  }, [dateRange]);

  const updateSeries = (responseData: any) => {
    // Separate dates and data
    const labels = Object.keys(responseData); // Use dates as labels
    const completedData = labels.map((date) => responseData[date].completed);
    const approvedData = labels.map((date) => responseData[date].approved);
    const requestedData = labels.map((date) => responseData[date].created);

    setSeries([
      { name: 'Tamamlandı', type: 'column', data: completedData },
      { name: 'Onaylandı', type: 'line', data: approvedData },
      { name: 'Talep Edildi', type: 'line', data: requestedData },
    ]);

    // Update xAxis labels
    setOptions((prevOptions: any) => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        categories: labels,
      },
    }));
  };

  return (
    <div>
      <Card className="border-radius-md">
        <Row justify={'space-between'} align={'middle'}>
          <Col>
            <h3 className="fs-24 fw-700 m-0">Canlı Ders</h3>
          </Col>
          <Col>
            <DateRange onChangeDateRange={setDateRange} />
          </Col>
        </Row>
        <Row className="mt-24">
          <Col span={24}>
            <ReactApexChart
              options={options}
              series={series}
              type="line"
              height={200}
            />
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default LiveLessonGraph;
