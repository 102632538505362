import { Col, Row } from 'antd';
import WelcomeCard from '../components/Dashboard/WelcomeCard';
import TotalStudent from '../components/Dashboard/TotalStudent';
import LiveLessonGraph from '../components/Dashboard/LiveLessonGraph';
import PopularCourses from '../components/Dashboard/PopularCourses';
import TotalPackages from '../components/Dashboard/TotalPackages';
import TotalSubject from '../components/Dashboard/TotalSubject';
import TotalLiveLessons from '../components/Dashboard/TotalLiveLessons';
import { useEffect, useState } from 'react';
import DashboardService from '../services/DashboardService';
import LastPurchasedCoursesTable from '../components/Dashboard/LastPurchasedCoursesTable';
import TeacherStatsTable from '../components/Dashboard/TeacherStatsTable';

const Dashboard = () => {
  const [data, setData] = useState<any>();

  const getData = () => {
    DashboardService.stats()
      .then((response: any) => {
        const data = response.data;
        setData(data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Row gutter={[24, 24]} style={{ display: 'flex', alignItems: 'stretch' }}>
        <Col xl={16}>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Row className="" gutter={[24, 24]} style={{ display: 'flex', alignItems: 'stretch' }}>
                <Col xs={24} md={6} style={{ display: 'flex' }}>
                  <TotalStudent data={data?.students} />
                </Col>
                <Col xs={24} md={6} style={{ display: 'flex' }}>
                  <TotalPackages data={data?.packages} />
                </Col>
                <Col xs={24} md={6} style={{ display: 'flex' }}>
                  <TotalSubject data={data?.subjects} />
                </Col>
                <Col xs={24} md={6} style={{ display: 'flex' }}>
                  <TotalLiveLessons data={data?.liveLessons} />
                </Col>
              </Row>
              {/* <WelcomeCard /> */}
            </Col>
            <Col span={24}>
              <LiveLessonGraph />
            </Col>
          </Row>
        </Col>
        <Col xl={8} style={{ display: 'flex', flexDirection: 'column' }}>
          <PopularCourses />
        </Col>
      </Row>
      <Row style={{ marginTop: 24 }}>
        <LastPurchasedCoursesTable />
      </Row>
      <Row style={{ marginTop: 24 }}>
        <TeacherStatsTable limit={5} />
      </Row>

    </>
  );
};

export default Dashboard;