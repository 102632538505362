import { Card, Table, Button, Row, Col, Select } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { formatDate, openNotification } from './utils';
import LiveLessonService from './services/LiveLessonService';
import ReminderService from './modules/Reminder/Services/ReminderService';
import { IReminderListResponse, IReminderRequest } from './modules/Reminder/Types/Reminder';
import LiveLessonLink from './components/LiveLesson/LiveLessonLink';
import { NavLink } from 'react-router-dom';
import StudentService from './services/StudentService';
import DateRange from './components/Common/DateRange';

const { Option } = Select;

// Define the type for status keys
type StatusKey = 'PENDING' | 'ACCEPT' | 'REJECT' | 'COMPLETED';

// Status mapping to Turkish
const statusMap: { [key in StatusKey]: string } = {
  PENDING: 'Beklemede',
  ACCEPT: 'Onaylandı',
  REJECT: 'Reddedildi',
  COMPLETED: 'Tamamlandı',
} as const;

const ReminderPage = () => {
  const [reminderList, setReminderList] = useState<IReminderListResponse[]>([]);
  const [statusFilter, setStatusFilter] = useState<StatusKey[]>([]);
  const [students, setStudents] = useState<any[]>([]);
  const [selectedStudent, setSelectedStudent] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState<any>([]);
  const [filter, setFilter] = useState<any>({
    startDate: dayjs().startOf('month').format('YYYY-MM-DDT00:00:00'),
    endDate: dayjs().endOf('month').format('YYYY-MM-DDT23:59:59'),
    status: statusFilter,
    studentId: null,
  });

  const getStudents = () => {
    setLoading(true);
    StudentService.getAll()
      .then((response: any) => {
        const data = response.data;
        const options = data.map(
          (lesson: { id: number; firstName: string; lastName: string }) => ({
            label: `${lesson.firstName} ${lesson.lastName}`,
            value: lesson.id,
          })
        );
        setStudents(options);
        setLoading(false);
      })
      .catch((e: Error) => {
        console.log(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    getStudents();
  }, []);

  const getReminderList = () => {
    const statusQuery =
      statusFilter.length > 0 ? `&status=${statusFilter.join(',')}` : '';
    const studentQuery = selectedStudent ? `&accountId=${selectedStudent}` : '';

    ReminderService.getAll(
      `?startDate=${filter.startDate}&endDate=${filter.endDate}${statusQuery}${studentQuery}`
    )
      .then((response: any) => {
        setReminderList(response.data.content);
      })
      .catch((e: Error) => {
        console.error(e);
      });
  };

  const handleStatusUpdate = (newStatus: StatusKey, reminder: any) => {
    reminder.status = newStatus;
    ReminderService.update(reminder.id, reminder)
      .then((response: any) => {
        getReminderList();
      })
      .catch((e: Error) => {
        console.error(e);
      });

  };

  useEffect(() => {
    getReminderList();
  }, [filter, statusFilter, selectedStudent]);

  useEffect(() => {
    setFilter((prev: any) => ({
      ...prev,
      startDate: dayjs(dateRange[0]).format('YYYY-MM-DDT00:00:00'),
      endDate: dayjs(dateRange[1]).format('YYYY-MM-DDT23:59:59'),
    }));
  }, [dateRange]);

  const handleStatusChange = (value: StatusKey[]) => {
    setStatusFilter(value);
    setFilter((prev: any) => ({
      ...prev,
      status: value,
    }));
  };

  const handleStudentChange = (value: string) => {
    setSelectedStudent(value);
    setFilter((prev: any) => ({
      ...prev,
      studentId: value,
    }));
  };

  const columns = [
    {
      title: 'Öğrenci',
      key: 'account',
      render: (reminder: any) => {
        return reminder.account ? (
          <>
            {reminder?.account?.firstName} {reminder?.account?.lastName}
          </>
        ) : null;
      },
    },
    {
      title: 'Ders Adı',
      key: 'lessonName',
      render: (reminder: any) => {
        const properties = JSON.parse(reminder.properties); // Parse the properties field
        return properties.lessonName ? (
          <span>{properties.lessonName}</span>
        ) : null;
      },
    },
    {
      title: 'Not',
      dataIndex: 'content',
      key: 'content',
    },
    {
      title: 'Durum',
      dataIndex: 'status',
      key: 'status',
      render: (status: StatusKey, reminder: any) => (
        <>
          <Select
            value={status}
            style={{ width: 150 }}
            onChange={(value: StatusKey) => handleStatusUpdate(value, reminder)}
            disabled={status !== 'PENDING'}
          >
            {(Object.keys(statusMap) as StatusKey[]).map((key) => (
              <Option key={key} value={key}>
                {statusMap[key]}
              </Option>
            ))}
          </Select>
        </>
      ),
    },
    {
      title: 'Tarih',
      dataIndex: 'reminderDate',
      key: 'reminderDate',
      render: (text: string) => <b>{formatDate(text)}</b>,
    },
  ];

  return (
    <Card
      title="Canlı Ders"
      extra={
        <Row gutter={[16, 16]} justify="space-between" align="middle">
          <Col>
            <Select
              mode="multiple"
              placeholder="Durum seçin"
              onChange={handleStatusChange}
              allowClear
              size="large"
              style={{ width: 200 }}
            >
              {(Object.keys(statusMap) as StatusKey[]).map((key) => (
                <Option key={key} value={key}>
                  {statusMap[key]}
                </Option>
              ))}
            </Select>
          </Col>
          <Col>
            <Select
              showSearch
              placeholder="Öğrenci seçin"
              onChange={handleStudentChange}
              allowClear
              size="large"
              style={{ width: 200 }}
              loading={loading}
              options={students}
            />
          </Col>
          <Col>
            <DateRange onChangeDateRange={setDateRange} />
          </Col>
          <Col>
            <NavLink to="/">
              <Button size="large">Geri</Button>
            </NavLink>
          </Col>
        </Row>
      }
    >
      <Table
        columns={columns}
        dataSource={reminderList}
        rowKey={(record, index) =>
          record.id ? record.id.toString() : `row-${index}`
        }
        pagination={false}
      />
    </Card>
  );
};

export default ReminderPage;
