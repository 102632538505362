import { Card, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import DashboardService from '../../services/DashboardService';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';

dayjs.locale('tr');

const PopularCourses = () => {
    const [series, setSeries] = useState<any>([]);
    const [options, setOptions] = useState<any>({
        chart: {
            type: 'bar',
            height: 400,
            stacked: true, 
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: true,
                barHeight: '70%',
                borderRadius: 5, // Daha düzgün bar köşeleri
            },
        },
        xaxis: {
            categories: [], // Tarihler burada gösterilecek
        },
        legend: {
            position: 'top',
            horizontalAlign: 'center',
            markers: {
                radius: 12,
            },
        },
        colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#FF5733', '#33FF57', '#3357FF', '#FF33A1', '#A133FF', '#33FFF3', '#FFA133', '#FF33D4', '#33FFA1', '#D433FF'],        dataLabels: {
            enabled: true,
            formatter: (val: number) => `${val}`, // Direkt sayı gösterimi
        },
        yaxis: {
            title: {
                text: undefined,
            },
            labels: {
                show: true,
            },
        },
        tooltip: {
            y: {
                formatter: (val: number) => `${val} satış`, // Tooltip veri gösterimi
            },
        },
    });

    const fetchCourseSales = async () => {
        try {
            const response = await DashboardService.courseSales();
            const data = response.data;
    
            if (!data || typeof data !== 'object') {
                console.error('Geçersiz veri formatı:', data);
                return;
            }
    
            // Tarihleri al ve sıralama işlemi
            const sortedDates = Object.keys(data)
                .filter((key) => key)
                .map((key) => ({
                    raw: key,
                    parsed: dayjs(key, 'MMM YYYY', 'en'),
                }))
                .sort((a, b) => b.parsed.valueOf() - a.parsed.valueOf()) // En büyükten küçüğe sıralama
                .map((item) => ({
                    formatted: item.parsed.format('MM/YY'), // Formatlanmış tarih
                    raw: item.raw, // Orijinal tarih
                }));
    
            console.log(sortedDates); // Tarih sıralamasını kontrol edin
    
            // Kurs isimlerini ayıkla
            const courseNames = Array.from(
                new Set(
                    Object.values(data).flatMap((monthData: any) =>
                        Object.keys(monthData || {})
                    )
                )
            );
    
            // Grafik için veri oluştur
            const seriesData = courseNames.map((courseName) => ({
                name: courseName,
                data: sortedDates.map(
                    (date) => data[date.raw]?.[courseName] || 0 // Sıralı tarihlerle eşleştir
                ),
            }));
    
            console.log(seriesData); // Verileri kontrol edin
    
            setSeries(seriesData);
    
            setOptions((prevOptions: any) => ({
                ...prevOptions,
                xaxis: {
                    ...prevOptions.xaxis,
                    categories: sortedDates.map((item) => item.formatted), // Sıralı tarihleri kullan
                },
            }));
        } catch (error) {
            console.error('Veri alınırken hata oluştu:', error);
        }
    };

    useEffect(() => {
        fetchCourseSales();
    }, []);

    return (
        <Card className="border-radius-md purchased-card" style={{ height: '550px' }}>
            <Row justify={'space-between'} align={'middle'}>
                <Col>
                    <h3 className="fs-24 fw-700 m-0">Yıllık Satışlar</h3>
                </Col>
            </Row>
            <Row className="mt-24">
                <Col span={24}>
                    <ReactApexChart
                        options={options}
                        series={series}
                        type="bar"
                        height={400} // Grafik yüksekliği
                    />
                </Col>
            </Row>
        </Card>
    );
};

export default PopularCourses;